import axios from 'axios'
import Http from "./Http";

const aws = require('aws-sdk')

aws.config.update({
    secretAccessKey: process.env.VUE_APP_AWS_SECRET,
    accessKeyId: process.env.VUE_APP_AWS_KEY,
})
const aws_s3 = new aws.S3({
    signatureVersion: 'v4',
    region: process.env.VUE_APP_AWS_REGION
})


export default {

    async singleUpload(bucket, folder, fileName, file){

            //console.log(file.type)
            const key = folder + fileName
            const params = {
                Bucket: bucket,
                Key: key,
                //Expires: 10,
                ACL: 'public-read'
            }
            const url = aws_s3.getSignedUrl('putObject', params)
            return axios.put(url, file, {
                headers: {
                    'Content-Type': file.type,
                },
            })
          .then(result => {
                const bucketUrl = decodeURIComponent(result.request.responseURL).split(key )[0]
                result.key = key
                result.fullPath = bucketUrl + key
                return result
                
          })
          .catch(() => {
                return false
                // TODO: error handling
          })
    },

    async deleteObjectByKey(key) {
        const params = {
          Bucket: process.env.VUE_APP_AWS_S3_BUCKET,
          Key: key,
        }
        const data = aws_s3.deleteObject(params).promise()
      
        return data
    },

    async getInstances() {
        return await Http.get(`/aws/instances`)
    },
    async getInstanceDetails(InstanceId) {
        return await Http.get(`/aws/instances/${InstanceId}`)
    },
    async startInstance(InstanceId) {
        return await Http.patch(`/aws/instances/start/${InstanceId}`)
    },
    async stopInstance(InstanceId) {
        return await Http.patch(`/aws/instances/stop/${InstanceId}`)
    },
    async getLoadBalancers(data) {
        return await Http.get(`/aws/loadbalancers`, { params: data })
    },
    async getTargetGroups() {
        return await Http.get(`/aws/targetgroups`)
    },
    async getTargetGroupDetails(targetGroupArn) {
        return await Http.get(`/aws/targetgroup/details?TargetGroupArn=${targetGroupArn}`)
    },
    async getTargetGroupInstances(targetGroupArn) {
        return await Http.get(`/aws/targetgroup/instances?targetGroupArn=${targetGroupArn}`)
    },
    async registerInstanceToTargetGroup(data) {
        return await Http.patch(`/aws/targetgroup/instances/register`, data)
    },
    async deregisterInstanceToTargetGroup(data) {
        return await Http.patch(`/aws/targetgroup/instances/deregister`, data)
    },
    async getRDSInstances(data) {
        return await Http.get(`/aws/rds_instances`, { params: data })
    },
    async createReadDBInstance(data) {
        return await Http.post(`/aws/rds_instances/read_instance`, data)
    },
    async removeReadDBInstance(DBInstanceIdentifier) {
        return await Http.delete(`/aws/rds_instances/read_instance/${DBInstanceIdentifier}`)
    },
    async getApplications(data) {
        return await Http.get(`/aws/applications`, { params: data })
    },
    async getSecurityGroups(data) {
        return await Http.get(`/aws/security_groups`, { params: data })
    },
    async addSecurityGroupRule(data) {
        return await Http.post(`/aws/security_groups/addrule`, data)
    },
    async removeSecurityGroupRule(data) {
        return await Http.post(`/aws/security_groups/removerule`, data)
    },
}



